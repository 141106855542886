:root {
  --loc-primary: #AD4594;
  --loc-secondary: #FFD78D;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 300;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-label {
  font-weight: 400;
}

.form-control {
  font-size: 0.9rem;
}

.card-header-custom {
  font-weight: bold;
}

.login-title {
  font-size: 26px;
  font-weight: lighter;
  color: white;
}

.login-title-mayo {
  font-size: 26px;
  font-weight: 700;
  font-style: oblique;
  color: white;
}

.hippa-text {
  font-size: 20px;
  color: white;
  font-style: italic;
}

.flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  width: 900px;
  height: 400px;
  margin: 20px;
}

.flip.hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flipper {
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transform-style: preserve-3d;

  -moz-transition: -moz-transform 0.5s;
  -moz-transform-style: preserve-3d;

  -o-transition: -o-transform 0.5s;
  -o-transform-style: preserve-3d;

  transition: transform 0.5s;
  transform-style: preserve-3d;

  position: relative;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.dscroll-menu {
  max-height: 300px;
  overflow: auto;
}

.profile-image {
  /*border-radius: 40px;*/
  aspect-ratio: 3/2;
  height: 90px;
  /*background-color: #ddd;*/
}

.purble-border {
  border-color: purple;
  border-style: solid;
  border-width: 1px;
}

.table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 0px;
  -webkit-box-shadow: 0 0 6px gray;
  -moz-box-shadow: 0 0 6px gray;
  box-shadow: 0 0 6px gray;
}

.table thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
  -moz-border-radius: 5px 0px 0px 0px;
  -webkit-border-radius: 5px 0px 0px 0px;
}

.table thead tr th:last-child {
  border-radius: 0px 5px 0px 0px;
  -moz-border-radius: 0px 5px 0px 0px;
  -webkit-border-radius: 0px 5px 0px 0px;
}

.table thead tr th {
  background-color: var(--loc-secondary);
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  text-align: center;
  font-weight: 300 !important;
  text-transform: uppercase;
}

.table thead tr th:last-child {
  border-right: 0px solid gray;
}

.table tbody tr td {
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
}

.table tbody tr:last-child td {
  border-bottom: 0px solid gray;
  border-right: 1px solid gray;
}

.table tbody tr td:last-child {
  border-right: 0px solid gray;
}

.list-style {
  list-style-type: none;
}

.list-item-style {
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0px;
}

.footer-custom {
  position: fixed;
  bottom: 0;
}

.text-red {
  color: red !important;
}

.test-tube {
  background-color: white;
  border: 0px;
  padding: 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: 0 0 6px rgba(185, 185, 185, 0.5);
  -moz-box-shadow: 0 0 6px rgba(185, 185, 185, 0.5);
  box-shadow: 0 0 6px rgba(185, 185, 185, 0.5);
}

.test-tube>div>img {
  width: 25px;
  height: 25px;
}

.spinner-container {
  position: absolute;
  min-width: 100px;
  min-height: 100px;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  /*border-radius: 10px;*/
}

.spinner-alignment {
  margin-top: 10px;
  margin-left: 10px;
  min-width: 80px;
  min-height: 80px;
}

.spinner-container-hide {
  display: none;
}


.screen {
  width: 100vw;
  min-height: 100vh;
  margin-bottom: 85px;
  overflow-x: hidden;
}

.app-title {
  font-weight: 600;
  text-transform: uppercase;
}

.title {
  font-weight: 600;
}

.camera-top-left {
  position: absolute;
  margin: 0;
  width: 25px;
  height: 25px;
  border-top: 5px solid #303030;
  border-left: 5px solid #303030;
  top: 0;
  left: 0;
}

.camera-top-right {
  position: absolute;
  margin: 0;
  width: 25px;
  height: 25px;
  border-top: 5px solid #303030;
  border-right: 5px solid #303030;
  top: 0;
  right: 0;
}


.camera-bottom-left {
  position: absolute;
  margin: 0;
  width: 25px;
  height: 25px;
  border-bottom: 5px solid #303030;
  border-left: 5px solid #303030;
  bottom: 0;
  left: 0;
}

.camera-bottom-right {
  position: absolute;
  margin: 0;
  width: 25px;
  height: 25px;
  border-bottom: 5px solid #303030;
  border-right: 5px solid #303030;
  bottom: 0;
  right: 0;
}

.scanner-mobile {
  position: relative;
  max-width: 100%;
  min-height: 300px;
}

.focus-holder-mobile {
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 280px;
  min-height: 380px;
}


.scanner {
  position: relative;
  width: 100%;
  min-height: 300px;
  overflow-x: hidden;
}

.focus-holder {
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 380px;
  min-height: 280px;
}

.barcode-scanner {
  min-width: 400px;
  min-height: 300px;
  position: relative;
}

.barcode-scanner>video {
  position: absolute;
  top: 0px;
  left: 0px;
}

.barcode-scanner>canvas {
  position: absolute;
  top: 0px;
  left: 0px;
}


.barcode-scanner-mobile {
  min-width: 300px;
  min-height: 400px;
  position: relative;
}

.barcode-scanner-mobile>video {
  position: absolute;
  top: 0px;
  left: 0px;
}

.barcode-scanner-mobile>canvas {
  position: absolute;
  top: 0px;
  left: 0px;
}


.tab-bar {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  position: relative;
  top: 0;
  width: 100%;
  background-color: #f8f8f8;
  font-weight: 650;
  font-size: 1.6rem;
  z-index: 2;
  box-shadow: 0px 0.5px #c0c0c0;
  box-sizing: border-box;

}

.tab {
  text-align: center;
  color: black;
  height: 100%;
}

.tab.active {
  color: var(--loc-primary);
  background-color: white;
  border: 1px #ddd solid;
  border-bottom-color: transparent;
}

.status {
  background-color: #ddd;
}

.status>a {
  text-decoration: none;
  color: #000;
}

.status>a:hover {
  text-decoration: none;
  color: #333;
}